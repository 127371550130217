.rowTotal {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 4px;
}

.row {
  display: flex;

  &:nth-child(2n+1) {
    background-color: var(--bg2ndRow);
  }

  &:hover {
    background-color: var(--bgHover);
  }

  >div {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  >.switch {
    width: 62px;
    padding: 4px 4px 4px 0;
  }

  >.tableGrid {
    flex: 1;
    display: grid;
    grid-gap: 4px;
    grid-template-columns:
      minmax(60px, 1fr) // userId
      minmax(140px, 2fr) // name
      minmax(140px, 3fr) // email
      160px // lastLogin
      160px // lastProjectCreated
      minmax(90px, 1fr) // loginCount
      minmax(90px, 1fr) // projectCount
      minmax(60px, 1fr) // totalSize
      minmax(80px, 1fr) // finishAudioSize
      minmax(80px, 1fr) // finishVideoSize
  ;
  }
}

.disabled {
  color: var(--lightText);
}

//.row > div {
//  padding-top: 4px;
//  padding-bottom: 4px;
//}

//.row:nth-child(2n+1) {
//  background-color: var(--bg2ndRow);
//}

//.row:hover {
//  background-color: var(--bgHover);
//}

.switchWrapper {
  min-height: unset;
}

.spinner {
  position: relative;
  min-height: 80px;
}
