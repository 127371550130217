.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 850px;
    font-size: 18px;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
}

.headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    width: 100%;
    background-color: white;
}

.header {
    max-width: 1000px;
    min-width: 850px;
    margin: 0 auto;
    height: fit-content;
}

.root h1 {
    margin-top: 0;
    margin-left: 0;
}

.root h2 {
    margin-left: 0;
}

.gray {
    background-color: #e6e6e6;
}

.first {
    margin-top: 95px;
    background: url('img/imageMain.jpg') no-repeat left top border-box;
    background-size: cover;
}
