/*@value clrText, clrWhite, clrBorderLight, clrBorder, clrError, clrSuccess, clrLightGray, clrGray from '../../constants/colors.css';*/
@value clrText: #656565;
@value clrWhite: #fff;
@value clrBorderLight: #e0e0e0;
@value clrBorder: #ccc;
@value clrError: #D6514B;
@value clrSuccess: #5CB85C;
@value clrLightGray: #f2f2f2;
@value clrGray: #656565;

.inputRoot {
    clear: both;
    padding-bottom: 2em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.inputRoot::after {
    content: " ";
    display: table;
    clear: both;
}

.inputRoot.spaceBottomsmall {
    padding-bottom: 5px;
}
.inputRoot.spaceBottommedium {
    padding-bottom: 1em;
}
.inputRoot.spaceBottomnone {
    padding-bottom: 0;
}

.label {
    width: 90px;
    display: block;
    line-height: 30px;
    padding: 4px 0;
    text-align: left;
    color: #999;
}
.label .labelText {
    line-height: 1.1;
    display: inline-block;
    vertical-align: middle;
}
.label.labelWidthfull {
    display: block;
    width: 100%;
    line-height: 1.2;
    padding-bottom: 10px;
}

.label.labelWidthfull + .inputWrapper > .input[readonly] {
    line-height: 1;
}

.label.labelWidthright {
    text-align: right;
    padding-right: 10px;
}

.label.labelWidthleft {
    text-align: left;
    width: 100%;
    padding-right: 10px;
}

.label.labelWidthBasic {
    width: 100%;
    padding-right: 10px;
}

.inputWrapper {
    flex: 1;
    position: relative;
    display: block;
    padding-right: var(--inputValidateFlagWidth);
    /*padding-left: 5px;*/
    margin-top: 0;
    /*height: 43px;*/
    min-height: 31px;
}

.noValidate {
    padding-right: 0;
}
.input {
    font: 14px/1.5 "Lato", "Helvetica Neue", Helvetica, sans-serif;
    line-height: 26px;
    min-height: 26px;
    margin-bottom: 1px;
    padding: 7px 15px;
    border: 1px solid clrBorderLight;
    color: #333;
    /*background-color: clrWhite;*/
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    box-shadow: inset 0 1px 1px #e9e7e7;
    border-radius: 10px;
    background-clip: padding-box;
}

.input:-internal-autofill-selected {
    background-color: clrWhite !important;
    color: #000 !important;
}

.input[readonly] {
    border-color: transparent;
    box-shadow: none;
    cursor: default;
    padding-left: 0;
}
.input[readonly]:focus {
    border-color: transparent;
}

.editable > * {
    cursor: pointer;
    padding-left: 5px;
}
.editable > *[readonly] {
    cursor: pointer;
}

.editable:hover {
    background: #f2f2f2;
    border-radius: 10px;
}

.flexCenter {
  display: flex;
  align-items: center;
}
.flex0 {
  flex: 0;
}
.flex1 {
  flex: 1;
}
.pointer {
  cursor: pointer;
}

input[type="checkbox"] {
    width: 26px;
    box-shadow: none;
}

select.input {
    height: 2em;
}

.input:focus,
.input:focus + .charCounter {
    border-color: #3875D7;
    outline: 0;
}

.inputDisabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.inputInvalid {
    border-color: #B94A48;
}

.error {
    position: relative;
    background-color: #D32F2F;
    color: #FFF;
    font-size: 12px;
    padding: 4px 10px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
}
.error::before {
    content: '';
    display: block;
    position: absolute;
    border: 7px outset transparent;
}

.errorBelow {
    display: inline-block;
    margin: 10px 0 0 0;
}

.errorBelow::before {
    left: 22px;
    top: -13px;
    border-bottom: 7px solid #D32F2F;
}

.inputRoot .validationIcon {
    position: absolute;
    right: 0;
    top: 1em;
    width: 0.7em;
}

.validationIcon-asterisk {
    color: darkgray;
}

.validationIcon-times {
    color: clrError;
}

.validationIcon-check {
    color: clrSuccess;
}

.textarea {
    display: block;
}
div.textarea {
    white-space: pre-wrap;
}
.textarea[readonly] {
    min-height: 100%;
    height: auto;
    resize: none;
}

.limitedTextarea {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
    box-sizing: border-box;
    height: 6em;
    border-radius: 4px 4px 0 0;
    background-clip: padding-box;
    scroll-padding: 50px 10px 10px 50px;
}

.charCounter {
    position: relative;
    float: right;
    text-align: right;
    background-color: #e6e6e6;
    color: #808080;
    margin: -1px 0 0;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}
.charCounter div {
    padding: 5px 10px 4px 0;
}

.radioButton {
    min-width: 100px;
    display: inline-block;
    line-height: 52px;
}

.radioButton input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    top: -10000px;
    left: -10000px;
}

.radioButton input + span {
    display: inline-block;
    border: 1px solid #000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
}

.radioButton input:checked + span {
    background: #000;
    box-shadow: inset 0 0 0 3px #fff;
}

.fileUploader {
    position: relative;
    background-color: clrLightGray;
    border-radius: 8px;
    border: 2px dashed clrBorder;
    background-clip: initial;
    overflow: hidden;
    padding: 30px 2%;
    text-align: center;
}

.fileUploader input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.fileUploader > p,
.fileUploader > div > p {
    margin-bottom: 1em;
    font-size: 14px;
    color: dimgray;

    /* color: clrGray; */
}

.fileUploaderButton {
    margin: 10px 0 0;
}

.rwCenter {
    font: 14px/1.5 "Lato", "Helvetica Neue", Helvetica, sans-serif;
    line-height: 26px;
    min-height: 26px;
    margin-bottom: 1px;
    padding: 7px 15px 7px 0;
    color: #333;
    /*background-color: clrWhite;*/
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    border-radius: 10px;
    background-clip: padding-box;
    border-color: transparent;
    box-shadow: none;
    /*cursor: default;*/
}
