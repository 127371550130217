.description {
    width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.nameGroup {
  flex: 1;
  display: flex;
  align-items: center;
}

.mediaItem {
  padding: 0 0 1ex 0;
  display: flex;
  flex-direction: row;
    flex: 1;
}

.mediaItem.videoItem {
  align-items: center;
}

.mediaItem.videoItem .mediaName h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.mediaItem.videoItem .mediaName p {
  margin: 0;
  font-size: 80%;
}

.mediaName {
  flex: 1;
  min-width: 20ex;
}

.mediaId {
  margin-left: 8px;
  min-width: 21ex;
  white-space: nowrap;
}

.size {
  min-width: 10ex;
  text-align: right;
  white-space: nowrap;
}

.date {
  margin-left: 8px;
  min-width: 11ex;
  width: 50%;
  white-space: nowrap;
  text-align: center;
}

.mediaActions {
  padding-left: 1.2em;
  padding-right: 0;
}

.mediaStatus {
  display: flex;
  padding-left: 0;
  /*padding-right: 1.2em;*/
}

.previewImage {
  width: 100px;
  height: 50px;
  object-fit: contain;
  margin: 10px 10px 10px 0;
}
